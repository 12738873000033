export enum ItemName {
    OsmumtensFang = "Osmumten's fang",
    AvernicDefender = "Avernic defender",
    DragonDefender = "Dragon defender",
    TorvaFullHelm = "Torva full helm",
    TorvaPlatebody = "Torva platebody",
    TorvaPlatelegs = "Torva platelegs",
    FerociousGloves = "Ferocious gloves",
    PrimordialBoots = "Primordial boots",
    AmuletOfTorture = "Amulet of torture",
    InfernalCape = "Infernal cape",
    NeitiznotFaceguard = "Neitiznot faceguard",
    BandosChestplate = "Bandos chestplate",
    BandosTassets = "Bandos tassets",
    FireCape = "Fire cape",
    AmuletOfFury = "Amulet of fury",
    GhraziRapier = "Ghrazi rapier",
    ZamorakianHasta = "Zamorakian hasta",
    ScytheOfVitur = "Scythe of vitur",
    ToxicBlowpipe = "Toxic blowpipe",
    DragonDart = "Dragon dart",
    AmethystDart = "Amethyst dart",
    MasoriMaskF = "Masori mask (f)",
    MasoriBodyF = "Masori body (f)",
    MasoriChapsF = "Masori chaps (f)",
    ZaryteVambraces = "Zaryte vambraces",
    NecklaceOfAnguish = "Necklace of anguish",
    AvasAssembler = "Ava's assembler",
    ArmadylHelmet = "Armadyl helmet",
    ArmadylChestplate = "Armadyl chestplate",
    ArmadylChainskirt = "Armadyl chainskirt",
    TwistedBow = "Twisted bow",
    DragonArrow = "Dragon arrow",
    BronzeArrow = "Bronze arrow",
    BarrowsGloves = "Barrows gloves",
    TumekensShadow = "Tumeken's shadow",
    SanguinestiStaff = "Sanguinesti staff",
    ElidinisWardF = "Elidinis' ward (f)",
    EternalBoots = "Eternal boots",
    AncestralHat = "Ancestral hat",
    AncestralRobeTop = "Ancestral robe top",
    AncestralRobeBottom = "Ancestral robe bottom",
    TormentedBracelet = "Tormented bracelet",
    OccultNecklace = "Occult necklace",
    ImbuedZamorakCape = "Imbued zamorak cape",
    AhrimsHood = "Ahrim's hood",
    AhrimsRobetop = "Ahrim's robetop",
    AhrimsRobeskirt = "Ahrim's robeskirt",
    BookOfTheDead = "Book of the dead",
    KerisPartisan = "Keris partisan",
    KerisPartisanOfCorruption = "Keris partisan of corruption",
    KerisPartisanOfTheSun = "Keris partisan of the sun",
    KerisPartisanOfBreaching = "Keris partisan of breaching",
    SlayerHelmetI = "Slayer helmet (i)",
    SalveAmulet = "Salve amulet",
    SalveAmuletE = "Salve amulet (e)",
    SalveAmuletI = "Salve amulet(i)",
    SalveAmuletEI = "Salve amulet(ei)",
    BerserkerRing = "Berserker ring",
    BerserkerRingI = "Berserker ring (i)",
    WarriorRingI = "Warrior ring (i)",
    VoidMeleeHelm = "Void melee helm",
    VoidRangerHelm = "Void ranger helm",
    VoidMageHelm = "Void mage helm",
    VoidKnightTop = "Void knight top",
    VoidKnightRobe = "Void knight robe",
    VoidKnightGloves = "Void knight gloves",
    EliteVoidTop = "Elite void top",
    EliteVoidRobe = "Elite void robe",
    UltorRing = "Ultor ring",
    BellatorRing = "Bellator ring",
    MagusRing = "Magus ring",
    VenatorRing = "Venator ring",
    SoulreaperAxe = "Soulreaper axe",
    Arclight = "Arclight",
    DragonHunterLance = "Dragon hunter lance",
    InquisitorsGreatHelm = "Inquisitor's great helm",
    InquisitorsHauberk = "Inquisitor's hauberk",
    InquisitorsPlateskirt = "Inquisitor's plateskirt",
    InquisitorsMace = "Inquisitor's mace",
    AbyssalBludgeon = "Abyssal bludgeon",
    AbyssalTentacle = "Abyssal tentacle",
    AbyssalWhip = "Abyssal whip",
    DragonBolts = "Dragon bolts",
    OpalBoltsE = "Opal bolts (e)",
    OpalDragonBoltsE = "Opal dragon bolts (e)",
    PearlBoltsE = "Pearl bolts (e)",
    PearlDragonBoltsE = "Pearl dragon bolts (e)",
    EmeraldBoltsE = "Emerald bolts (e)",
    EmeraldDragonBoltsE = "Emerald dragon bolts (e)",
    RubyBoltsE = "Ruby bolts (e)",
    RubyDragonBoltsE = "Ruby dragon bolts (e)",
    DiamondBoltsE = "Diamond bolts (e)",
    DiamondDragonBoltsE = "Diamond dragon bolts (e)",
    DragonstoneBoltsE = "Dragonstone bolts (e)",
    DragonstoneDragonBoltsE = "Dragonstone dragon bolts (e)",
    OnyxBoltsE = "Onyx bolts (e)",
    OnyxDragonBoltsE = "Onyx dragon bolts (e)",
    DragonCrossbow = "Dragon crossbow",
    ArmadylCrossbow = "Armadyl crossbow",
    DragonHunterCrossbow = "Dragon hunter crossbow",
    ZaryteCrossbow = "Zaryte crossbow",
    TwistedBuckler = "Twisted buckler",
    PegasianBoots = "Pegasian boots",
    CrystalHelm = "Crystal helm",
    CrystalBody = "Crystal body",
    CrystalLegs = "Crystal legs",
    BowOfFaerdhinen = "Bow of faerdhinen",
    StaffOfTheDead = "Staff of the dead",
    TomeOfFire = "Tome of fire",
    TomeOfWater = "Tome of water",
    NightmareStaff = "Nightmare staff",
    HarmonisedNightmareStaff = "Harmonised nightmare staff",
    VirtusMask = "Virtus mask",
    VirtusRobeTop = "Virtus robe top",
    VirtusRobeBottom = "Virtus robe bottom",
    KodaiWand = "Kodai wand",
    BronzeSpear = "Bronze spear",
    IronSpear = "Iron spear",
    SteelSpear = "Steel spear",
    BlackSpear = "Black spear",
    MithrilSpear = "Mithril spear",
    AdamantSpear = "Adamant spear",
    RuneSpear = "Rune spear",
    DragonSpear = "Dragon spear",
    BoneSpear = "Bone spear",
    GildedSpear = "Gilded spear",
    GuthansWarspear = "Guthan's warspear",
    ZamorakianSpear = "Zamorakian spear",
    LeafBladedSpear = "Leaf-bladed spear",
    LeafBladedBattleaxe = "Leaf-bladed battleaxe",
    LeafBladedSword = "Leaf-bladed sword",
    BroadArrows = "Broad arrows",
    BroadBolts = "Broad bolts",
    AmethystBroadBolts = "Amethyst broad bolts",
    SlayersStaff = "Slayer's staff",
    SlayersStaffE = "Slayer's staff (e)",
    BronzeHalberd = "Bronze halberd",
    IronHalberd = "Iron halberd",
    SteelHalberd = "Steel halberd",
    BlackHalberd = "Black halberd",
    WhiteHalberd = "White halberd",
    MithrilHalberd = "Mithril halberd",
    AdamantHalberd = "Adamant halberd",
    RuneHalberd = "Rune halberd",
    DragonHalberd = "Dragon halberd",
    CrystalHalberd = "Crystal halberd",
    BladeOfSaeldor = "Blade of saeldor",
    DragonScimitar = "Dragon scimitar",
    SaradominSword = "Saradomin sword",
    StarterStaff = "Starter staff",
    TridentOfTheSeas = "Trident of the seas",
    TridentOfTheSeasE = "Trident of the seas (e)",
    ThammaronsSceptre = "Thammaron's sceptre",
    AccursedSceptre = "Accursed sceptre",
    TridentOfTheSwamp = "Trident of the swamp",
    TridentOfTheSwampE = "Trident of the swamp (e)",
    HolySanguinestiStaff = "Holy sanguinesti staff",
    Dawnbringer = "Dawnbringer",
    CrystalStaffBasic = "Crystal staff (basic)",
    CorruptedStaffBasic = "Corrupted staff (basic)",
    CrystalStaffAttuned = "Crystal staff (attuned)",
    CorruptedStaffAttuned = "Corrupted staff (attuned)",
    CrystalStaffPerfected = "Crystal staff (perfected)",
    CorruptedStaffPerfected = "Corrupted staff (perfected)",
    SwampLizard = "Swamp lizard",
    OrangeSalamander = "Orange salamander",
    RedSalamander = "Red salamander",
    BlackSalamander = "Black salamander",
    CrystalBow = "Crystal bow",
    ChaosGauntlets = "Chaos gauntlets",
    MysticSmokeStaff = "Mystic smoke staff",
    SmokeBattlestaff = "Smoke battlestaff",
    StaffOfFire = "Staff of fire",
    DragonfireShield = "Dragonfire shield",
    ToktzXilEk = "Toktz-xil-ek",
    ToktzXilAk = "Toktz-xil-ak",
    TzhaarKetEm = "Tzhaar-ket-em",
    TzhaarKetOm = "Tzhaar-ket-om",
    TzhaarKetOmT = "Tzhaar-ket-om (t)",
    ToktzXilUl = "Toktz-xil-ul",
    ToktzMejTal= "Toktz-mej-tal",
    ObsidianHelmet = "Obsidian helmet",
    ObsidianPlatebody = "Obsidian platebody",
    ObsidianPlatelegs = "Obsidian platelegs",
    BerserkerNecklace = "Berserker necklace",
    AmuletOfAvarice = "Amulet of avarice",
    IvandisFlail = "Ivandis flail",
    BlisterwoodSickle = "Blisterwood sickle",
    BlisterwoodFlail = "Blisterwood flail",
    BlessedAxe = "Blessed axe",
    EfaritaysAid = "Efaritay's aid",
    SilverBolts = "Silver bolts",
    SilverSickle = "Silver sickle",
    SilverSickleB = "Silver sickle (b)",
    EmeraldSickleB = "Emerald sickle (b)",
    EnchantedEmeraldSickleB = "Enchanted emerald sickle (b)",
    RubySickleB = "Ruby sickle (b)",
    EnchantedRubySickleB = "Enchanted ruby sickle (b)",
    Silverlight = "Silverlight",
    Darklight = "Darklight",
    RodOfIvandis = "Rod of ivandis",
    Wolfbane = "Wolfbane",
    WarpedSceptre = "Warped sceptre",
    ElderMaul = "Elder maul"
}
