export enum SpellName {
    WindStrike = "Wind Strike",
    WaterStrike = "Water Strike",
    EarthStrike = "Earth Strike",
    FireStrike = "Fire Strike",
    WindBolt = "Wind Bolt",
    WaterBolt = "Water Bolt",
    EarthBolt = "Earth Bolt",
    FireBolt = "Fire Bolt",
    WindBlast = "Wind Blast",
    WaterBlast = "Water Blast",
    CrumbleUndead = "Crumble Undead",
    EarthBlast = "Earth Blast",
    FireBlast = "Fire Blast",
    WindWave = "Wind Wave",
    WaterWave = "Water Wave",
    EarthWave = "Earth Wave",
    SaradominStrike = "Saradomin Strike",
    ClawsOfGuthix = "Claws of Guthix",
    FlamesOfZamorak = "Flames of Zamorak",
    FireWave = "Fire Wave",
    WindSurge = "Wind Surge",
    WaterSurge = "Water Surge",
    EarthSurge = "Earth Surge",
    FireSurge = "Fire Surge",
    IbanBlast = "Iban Blast",
    MagicDart = "Magic Dart",

    GhostlyGrasp = "Ghostly Grasp",
    SkeletalGrasp = "Skeletal Grasp",
    UndeadGrasp = "Undead Grasp",
    InferiorDemonbane = "Inferior Demonbane",
    SuperiorDemonbane = "Superior Demonbane",
    DarkDemonbane = "Dark Demonbane",

    SmokeRush = "Smoke Rush",
    ShadowRush = "Shadow Rush",
    BloodRush = "Blood Rush",
    IceRush = "Ice Rush",
    SmokeBurst = "Smoke Burst",
    ShadowBurst = "Shadow Burst",
    BloodBurst = "Blood Burst",
    IceBurst = "Ice Burst",
    SmokeBlitz = "Smoke Blitz",
    ShadowBlitz = "Shadow Blitz",
    BloodBlitz = "Blood Blitz",
    IceBlitz = "Ice Blitz",
    SmokeBarrage = "Smoke Barrage",
    ShadowBarrage = "Shadow Barrage",
    BloodBarrage = "Blood Barrage",
    IceBarrage = "Ice Barrage",

}
